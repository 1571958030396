import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from "../components/SEO/seo";
import Pagebuilder from '../components/pagebuilder'

export default function Template({ data }) {
  return (
    <Layout dark={true}>
      <SEO
        pageTitle={data.page.frontmatter.title}
        pageDescription={data.page.frontmatter.metaDescription}
        pageImage={data.page.frontmatter.metaImage}
        url={data.page.frontmatter.path}
      />
      <Pagebuilder blocks={data.page.frontmatter.content_blocks} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        metaDescription
        template
        content_blocks {
          type
          title
          body
          intro
          header
          work
          include_form
          # cta_text
          # cta_link
          cta_1_text
          cta_1_link
          # cta_2_text
          # cta_2_link
          # cta_3_text
          # cta_3_link
          # text
          # image_leftright
          full_width
          # cards {
          #   cta_link
          #   cta_text
          #   intro
          #   title
          # }
          image {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1000, quality: 69) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
